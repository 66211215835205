.docs-list {
    gap: 0.5%;
    width: 99%;
    flex-wrap: wrap;
}

input[type="text"]#clientId {
    color: rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
    height: 0;
}

input[type="text"]#clientId:focus-visible {
    outline: none;
}

body {
    /* Quick work arround need to change if any better this is to fix on expand drop down*/
    padding-right: 0px !important;
    overflow: hidden;
}

.mr-20 {
    margin-right: -20px;
}

.mb-200{
    margin-bottom: 200px;
}